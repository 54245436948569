.w-pagination-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.w-pagination-previous {
  display: block;
  color: #333;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fafafa;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-color: #ccc;
  border-right-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.w-pagination-previous-icon {
  margin-right: 4px;
}

.w-pagination-next {
  display: block;
  color: #333;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fafafa;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-color: #ccc;
  border-right-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.w-pagination-next-icon {
  margin-left: 4px;
}

body {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

h5 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #f6f6f9;
}

blockquote {
  margin-bottom: 10px;
  padding: 10px 20px;
  border-left: 5px solid #e2e2e2;
  font-size: 1em;
  line-height: 1.6em;
}

.image {
  height: 100vh;
  max-width: none;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  padding-top: 6vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/NOWHERE-BG-2.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.moons {
  position: fixed;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin-top: 40px;
  margin-right: 40px;
  opacity: 0.5;
}

.logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.logo-img {
  width: 23vw;
  -o-object-fit: contain;
  object-fit: contain;
}

.logo-img.here {
  -o-object-fit: contain;
  object-fit: contain;
}

.logo-container {
  position: relative;
  z-index: 1;
  margin-bottom: 5vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.welcome-img {
  width: 25vw;
  margin-bottom: -10.4vw;
}

.welcome-img.you {
  margin-bottom: -12vw;
  opacity: 0;
}

.image-5 {
  width: 28vw;
}

.welcome-div {
  position: absolute;
  z-index: -1;
  display: -ms-grid;
  display: grid;
  margin-top: -8.5vw;
  margin-left: -4vw;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  justify-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.here-container {
  margin-left: 3.2vw;
}

.paragraph-centered-spaced {
  color: #fff;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.paragraph-centered-spaced.body {
  background-color: transparent;
  font-size: 14px;
  text-align: left;
  text-transform: none;
}

.message-div {
  margin-bottom: 10px;
}

.form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-radius: 2px;
}

.submit-button {
  height: 40px;
  margin-left: 0px;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 0px 5px 5px 0px;
  background-color: rgba(165, 37, 167, 0.5);
  box-shadow: 1px 1px 5px 1px rgba(165, 37, 167, 0.25);
  opacity: 1;
  -webkit-transition: background-color 800ms cubic-bezier(.165, .84, .44, 1);
  transition: background-color 800ms cubic-bezier(.165, .84, .44, 1);
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #a525a7;
}

.submit-button.round {
  position: absolute;
  z-index: 1;
  border-radius: 20px;
  background-color: #a525a7;
}

.submit-button.round:hover {
  background-color: #00c3ff;
}

.submit-button.round.invite {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.success-message {
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 100px;
  background-color: rgba(165, 37, 167, 0.2);
  font-family: all-round-gothic, sans-serif;
  color: #fff;
}

.form-input {
  height: 40px;
  margin-right: 10px;
  border-radius: 5px 0px 0px 5px;
  background-color: hsla(0, 0%, 100%, 0.1);
  box-shadow: 1px 1px 10px 2px rgba(165, 37, 167, 0.5);
  color: #fff;
}

.form-input.round {
  min-width: 270px;
  border-radius: 20px;
}

.form-input.invite {
  width: 300px;
}

.form-container {
  position: relative;
  z-index: 2;
  min-height: 100px;
}

.moose {
  position: relative;
  width: 16vw;
  -o-object-fit: contain;
  object-fit: contain;
}

.moose-container {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.body {
  background-color: #000;
  font-family: all-round-gothic, sans-serif;
  color: #fff;
  font-size: 16px;
}

.body.blue {
  background-color: #010027;
  font-size: 16px;
}

.body.transparent {
  background-color: transparent;
}

.logo-containter-2 {
  position: relative;
  margin-top: 29px;
}

.section-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.error-message {
  margin-top: 0px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgba(165, 37, 167, 0.5);
  font-family: Ubuntu, Helvetica, sans-serif;
  color: #fff;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Ubuntu, Helvetica, sans-serif;
  color: #fff;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.section-main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.section-main.centered {
  clear: right;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.body-2 {
  background-color: #010027;
  background-image: url('../images/NOWHERE-BG-2.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: all-round-gothic, sans-serif;
  color: #fff;
  font-size: 16px;
}

.body-2.create {
  background-image: none;
}

.logo-block {
  position: fixed;
  width: 25vw;
  padding-top: 2vw;
  padding-left: 2vw;
}

.main-content-block {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80vw;
  height: 100%;
  padding-top: 179px;
  padding-bottom: 20px;
  padding-left: 120px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top-left-radius: 5vw;
  border-bottom-left-radius: 5vw;
  background-color: #010027;
  box-shadow: 1px 0 60px 0 rgba(0, 0, 0, 0.5);
}

.main-content-block.create {
  position: static;
  width: 75vw;
  height: auto;
  min-height: 100vh;
  padding-left: 0vw;
  border-bottom-left-radius: 0vw;
}

.logo-img-2 {
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
}

.logo-img-2.here {
  -o-object-fit: contain;
  object-fit: contain;
}

.h1 {
  text-shadow: 1px 1px 15px rgba(0, 0, 0, 0.35);
  font-size: 60px;
  font-weight: bold;
  line-height: 65px;
  color: white;
}

.h1.center {
  text-align: center;
  letter-spacing: 10px;
  text-transform: none;
  text-shadow: 1px 1px 10px #a525a7;
}

.h1.center.big {
  font-size: 8em;
}

.h1.horiz-title {
  margin-left: -197px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.h1.create {
  margin-top: 0px;
  line-height: 1em;
}

.h1.horiz-title-copy {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  line-height: 1em;
}

.button {
  border-radius: 20px;
  background-color: #fff;
  -webkit-transition: color 750ms cubic-bezier(.165, .84, .44, 1), background-color 750ms cubic-bezier(.165, .84, .44, 1);
  transition: color 750ms cubic-bezier(.165, .84, .44, 1), background-color 750ms cubic-bezier(.165, .84, .44, 1);
  color: #010027;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.button:hover {
  background-color: #a525a7;
  color: #fff;
}

.button.patreon {
  margin-top: 15px;
  padding-left: 50px;
  background-image: url('../images/Patreon_Mark_Black.svg');
  background-position: 15px 50%;
  background-size: auto 20px;
  background-repeat: no-repeat;
  text-align: left;
}

.button.dele {
  background-color: #9a0a0a;
  color: #fff;
}

.title-div {
  margin-bottom: 140px;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.submit-button-2 {
  border-radius: 20px;
  background-color: #010027;
}

.heading {
  color: #010027;
}

.form-2 {
  max-width: 400px;
  min-width: 200px;
}

.form-2.email {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 550px;
  margin-bottom: 30px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.form-block-2 {
  width: 100%;
  margin-top: 2vw;
  padding-right: 5vw;
}

.signup-animation {
  width: 120px;
  height: 40px;
  background-image: url('../images/loading-40px_1loading-40px.gif');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  margin-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.div-block.invite {
  margin-top: 0px;
}

.signup-error {
  margin-top: 10px;
  color: red;
}

.error-message {
  padding-left: 15px;
  background-color: transparent;
  font-family: all-round-gothic, sans-serif;
  color: red;
}

.navbar {
  background-color: transparent;
  color: #fff;
  position: fixed;
  z-index: 10;
  width: 100%;
  margin-bottom: -80px;
  padding: 20px;
}

.nav-grid {
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  justify-items: stretch;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: 1fr max-content max-content max-content;
  grid-template-columns: 1fr -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: 1fr max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.nav-logo-link {
  display: block;
  height: 40px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
}

.nav-logo {
  width: auto;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.nav-link {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 0px;
  border-width: 0px;
  border-color: #a525a7;
  border-bottom-style: solid;
  -webkit-transition: border-color 750ms cubic-bezier(.165, .84, .44, 1), color 750ms cubic-bezier(.165, .84, .44, 1);
  transition: border-color 750ms cubic-bezier(.165, .84, .44, 1), color 750ms cubic-bezier(.165, .84, .44, 1);
  color: #fff;
  text-decoration: none;
  text-shadow: 1px 1px 6px #a525a7;
}

.nav-link:hover {
  border-bottom: 2px solid #00c3ff;
  color: #00c3ff;
  text-decoration: none;
}

.nav-link.w--current {
  border-bottom-width: 2px;
  border-bottom-color: #00c3ff;
  color: #00c3ff;
}

.nav-link.home {
  padding-right: 15px;
  padding-left: 15px;
  background-color: rgba(0, 196, 255, 0.01);
  -webkit-transition: border-radius 750ms cubic-bezier(.165, .84, .44, 1), background-color 750ms cubic-bezier(.165, .84, .44, 1), color 750ms cubic-bezier(.165, .84, .44, 1);
  transition: border-radius 750ms cubic-bezier(.165, .84, .44, 1), background-color 750ms cubic-bezier(.165, .84, .44, 1), color 750ms cubic-bezier(.165, .84, .44, 1);
  text-shadow: 1px 1px 4px #a525a7;
}

.nav-link.home:hover {
  border-bottom-width: 0px;
  border-radius: 20px;
  background-color: #a525a7;
}

.loading-screen {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: none;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #010027;
}

.log-in-home {
  position: fixed;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin-top: 2vw;
  margin-right: 2vw;
}

.list-item-hidden {
  display: none;
}

.html-embed {
  margin-bottom: 15px;
  font-size: 1em;
  line-height: 1.8em;
}

.line {
  width: 100%;
  height: 2px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #fff;
}

.sticky-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  padding: 20px;
  background-color: #f4f4f4;
}

.nav-grid-2 {
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: 1fr max-content max-content max-content;
  grid-template-columns: 1fr -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: 1fr max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.nav-link-2 {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 0px;
  color: #444;
  text-decoration: none;
}

.nav-link-2:hover {
  text-decoration: underline;
}

.patreon-div {
  position: fixed;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  margin-top: 2vw;
  margin-right: 2vw;
  margin-bottom: 2vw;
}

.dropdown {
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 0px 0px 20px 20px;
  background-color: #1c0321;
  -webkit-transition: border-radius 500ms cubic-bezier(.165, .84, .44, 1);
  transition: border-radius 500ms cubic-bezier(.165, .84, .44, 1);
}

.dropdown:focus {
  border-bottom-left-radius: 0px;
}

.dropdown.big {
  width: 100%;
  border-bottom-right-radius: 0px;
}

.dropdown.small {
  max-width: 50%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 10px;
}

.dropdown.white {
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
}

.dropdown.white:hover {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.dropdown.white:focus {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 6vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.dropdown-toggle.small {
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 10px;
  background-color: #140217;
  -webkit-transition: border-radius 750ms cubic-bezier(.165, .84, .44, 1);
  transition: border-radius 750ms cubic-bezier(.165, .84, .44, 1);
}

.dropdown-toggle.small:focus {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.hero-section {
  display: -ms-grid;
  display: grid;
  height: 80vh;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.hero-section.full-screen {
  height: 100vh;
}

.content-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 3fr;
  grid-template-columns: 1fr 3fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.background-video {
  height: 100%;
}

.club-title {
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: 6vw;
  padding-left: 6vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
}

.club-title.hide {
  display: none;
}

.horiz-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 270px;
  height: 180px;
  margin-left: -101px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
}

.horiz-title._2 {
  margin-left: 0px;
  padding-left: 35px;
}

.right-content {
  width: 650px;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 566px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-transform: translate(-8vw, 0px);
  -ms-transform: translate(-8vw, 0px);
  transform: translate(-8vw, 0px);
}

.list-item-2 {
  display: none;
}

.para-space {
  margin-top: 30px;
  margin-bottom: 30px;
}

.para-space.more {
  margin-top: 120px;
}

.paragraph-2 {
  font-size: 1em;
  line-height: 1.6em;
}

.paragraph-3 {
  font-size: 1em;
  line-height: 1.4em;
}

.capacity-embed {
  width: 207px;
  margin-top: 15px;
  font-size: 1em;
  text-align: center;
}

.h2 {
  font-size: 3em;
  line-height: 1em;
  font-weight: 700;
}

.h2.dark {
  color: #010027;
}

.h2.dark.outline {
  color: #fff;
}

.h2.dark.outline-shadows {
  color: #fff;
  text-shadow: -1px 1px 0 #010027, 1px -1px 0 #010027, -1px -1px 0 #010027, 1px 1px 0 #010027, 0 -1px 0 #010027, -1px 0 0 #010027, 0 1px 0 #010027, 1px 0 0 #010027;
}

.h2.dark.link {
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 5px;
  background-color: transparent;
  -webkit-transition: background-color 750ms cubic-bezier(.165, .84, .44, 1), color 750ms cubic-bezier(.23, 1, .32, 1);
  transition: background-color 750ms cubic-bezier(.165, .84, .44, 1), color 750ms cubic-bezier(.23, 1, .32, 1);
  color: #010027;
  font-size: 3em;
  text-decoration: underline;
}

.h2.dark.link:hover {
  padding-right: 5px;
  padding-left: 5px;
  background-color: #00c3ff;
}

.text-field {
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
}

.list-item-3 {
  margin-bottom: 15px;
}

.list {
  margin-top: 30px;
}

.link {
  -webkit-transition: color 750ms cubic-bezier(.23, 1, .32, 1);
  transition: color 750ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  font-size: 14px;
}

.link:hover {
  color: #00c3ff;
}

.link.negative {
  padding-right: 30px;
  color: #9a0a0a;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
}

.link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.block-quote {
  margin-bottom: 30px;
  font-size: 1em;
  line-height: 1.6em;
}

.tag {
  margin-bottom: 5px;
  font-size: 1.3em;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.tag.small {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-size: 0.7em;
  line-height: 1em;
}

.tag.dark {
  color: #010027;
}

.create-header-block {
  width: 100%;
  min-height: 350px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-left-radius: 5vw;
  border-bottom-left-radius: 20px;
}

.collection-item {
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.collection-item.horiz {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.dropdown-tag {
  margin-bottom: 10px;
  font-size: 1em;
  line-height: 1.4em;
}

.dropdown-bg {
  padding-top: 15px;
  background-color: #1c0321;
}

.text-block {
  color: #fff;
}

.icon {
  margin-left: 30px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.big-dropdown-text {
  color: #fff;
  font-size: 3em;
  line-height: 1em;
  font-weight: 200;
}

.dropdown-text-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.bold-text {
  color: #fff;
  line-height: 1em;
}

.time-settings-div {
  margin-right: 6vw;
  margin-left: 6vw;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.dropdown-text-small {
  color: #fff;
  font-size: 1em;
  line-height: 1.2em;
}

.small-div-line {
  width: 2px;
  margin-right: 15px;
  margin-left: 15px;
  background-color: #fff;
}

.small-div-line.dark {
  width: 1px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  background-color: #010027;
}

.dropdown-list {
  background-color: #27052e;
}

.dropdown-list.w--open {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #140217;
  color: #fff;
}

.dropdown-list.big.w--open {
  margin-left: 20px;
  border-bottom-left-radius: 20px;
}

.dropdown-list.light {
  border-radius: 0px 0px 20px 20px;
  background-color: #fff;
}

.dropdown-link {
  border-top: 1px solid #fff;
  -webkit-transition: background-color 500ms cubic-bezier(.165, .84, .44, 1);
  transition: background-color 500ms cubic-bezier(.165, .84, .44, 1);
  color: #fff;
}

.dropdown-link:hover {
  background-color: #27052e;
}

.dropdown-link.light {
  -webkit-transition: color 750ms cubic-bezier(.165, .84, .44, 1), background-color 750ms cubic-bezier(.165, .84, .44, 1);
  transition: color 750ms cubic-bezier(.165, .84, .44, 1), background-color 750ms cubic-bezier(.165, .84, .44, 1);
  color: #010027;
}

.dropdown-link.light:hover {
  color: #fff;
}

.cap-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  color: #fff;
}

.text-block-2 {
  margin-right: 15px;
  margin-left: 30px;
}

.small-dropdown-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2vw;
  margin-right: 4vw;
  margin-left: 4vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.left-image-div {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: -1;
  width: 30vw;
  height: 100vh;
}

.div-block-4 {
  height: 100vh;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.invite-users-div {
  width: 100%;
  padding-top: 2vw;
  padding-right: 6vw;
  padding-left: 6vw;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.user-list-div {
  display: block;
  margin-top: 3vw;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.create-top {
  padding-top: 12vw;
  border-top-left-radius: 5vw;
  border-bottom-left-radius: 20px;
  background-color: #27052e;
}

.create-top.edit {
  padding-right: 6vw;
  padding-bottom: 2vw;
  padding-left: 6vw;
}

.title-cap-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.user-btns-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.user-collection-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
}

.user-email {
  margin-left: 15px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.invited-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-5 {
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-color: #fff;
}

.primary-button-sticky {
  position: fixed;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  padding: 3vw;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  border-top-left-radius: 20px;
}

.primary-button-sticky.right {
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
}

.spacer {
  width: 2vw;
}

.dropdown-text-div2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.icon-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.icon-btn.edit {
  width: 16px;
  height: 16px;
}

.icon-btn.solid {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(28, 3, 33, 0.75);
}

.icon-btn.solid.space {
  margin-right: 15px;
}

.grid-container {
  max-width: 1920px;
  margin-top: 100px;
  padding-right: 60px;
  padding-left: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.h3 {
  font-size: 1.8em;
  line-height: 1.4em;
  font-weight: 700;
  letter-spacing: 1px;
}

.h3.dark {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #010027;
  font-size: 1.5em;
  line-height: 1.5em;
}

.h3.center {
  text-align: center;
  letter-spacing: 1px;
}

.card-master-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 10px;
  background-color: #fff;
  color: #010027;
}

.collection-item-3 {
  max-width: 100%;
  min-height: 150px;
  min-width: 540px;
}

.img-room-thumb {
  width: 120px;
  height: 120px;
  margin-right: 15px;
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
}

.card-row-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.paragraph {
  font-size: 1em;
  line-height: 1.6em;
}

.paragraph._1-line {
  overflow: hidden;
  line-height: 1em;
  white-space: nowrap;
}

.paragraph.center {
  margin-top: 60px;
  text-align: center;
}

.card-item {
  max-width: 175px;
  padding-right: 15px;
}

.date-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.collection-list-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-column-count: 2;
  column-count: 2;
}

.card-btns-div {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  margin-right: 24px;
}

.environment-div {
  margin-top: 2vw;
}

.start-end-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 6vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.start-end-div.small {
  width: 100%;
  margin-right: 2vw;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  border-radius: 10px;
  background-color: #140217;
  -webkit-transition: border-radius 750ms cubic-bezier(.165, .84, .44, 1);
  transition: border-radius 750ms cubic-bezier(.165, .84, .44, 1);
}

.start-end-div.small:focus {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.date-box-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-left: 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.empty-state {
  background-color: transparent;
}

.left-bg-img {
  height: 100vh;
  background-image: url('../images/secretCitadel_2.effectsResult_faces.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.div-block-7 {
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
}

.html-embed-2 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  overflow: hidden;
}

.bg-vid-containt {
  position: relative;
  overflow: hidden;
  max-height: 80vh;
  padding-top: 56.25%;
}

.div-block-9 {
  position: relative;
  z-index: -10;
}

.rich-text-block {
  margin-bottom: 30px;
  font-size: 1em;
  line-height: 1.4em;
}

.heading-2 {
  font-weight: 400;
}

.div-block-10 {
  position: absolute;
  left: 0px;
  margin-top: 80px;
  margin-left: 30px;
}

.div-block-11 {
  margin-top: 60px;
  padding-right: 6vw;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.vanity-main-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding-top: 120px;
  padding-right: 6vw;
  padding-left: 6vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #010027;
}

.vanity-main-section.centered {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.left-title {
  width: 129px;
  height: 580px;
  margin-right: 60px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  background-image: url('../images/VideoCheck-InStation.svg');
  background-position: 100% 0%;
  background-size: contain;
  background-repeat: no-repeat;
}

.logo-link {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  display: block;
  height: 40px;
  margin-top: 25px;
  margin-right: 0px;
  margin-left: 25px;
  padding: 0px;
}

.vanity-right-content {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 720px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
}

.video-check-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.video-mask {
  position: relative;
  z-index: 1;
  margin-bottom: -95px;
}

.video-check-holder-div {
  width: 300px;
}

.dropdown-toggle-2 {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-toggle-2.w--open {
  z-index: 1;
}

.dropdown-toggle-2.light {
  position: static;
  border: 1px none #000;
}

.bold-text-2 {
  font-family: all-round-gothic, sans-serif;
}

.dropdown-list-2 {
  position: static;
}

.dropdown-list-2.w--open {
  position: absolute;
  z-index: 0;
  margin-top: 110px;
  padding-top: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #fff;
}

.dropdown-video {
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 0px 0px 20px 20px;
  background-color: #1c0321;
  -webkit-transition: border-radius 500ms cubic-bezier(.165, .84, .44, 1);
  transition: border-radius 500ms cubic-bezier(.165, .84, .44, 1);
}

.dropdown-video:focus {
  border-bottom-left-radius: 0px;
}

.dropdown-video.big {
  width: 100%;
  border-bottom-right-radius: 0px;
}

.dropdown-video.small {
  max-width: 50%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 10px;
}

.dropdown-video.white {
  z-index: 0;
  width: 256px;
  height: 130px;
  margin-right: 22px;
  margin-left: 22px;
  background-color: #fff;
}

.dropdown-video.white:hover {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.audio-check {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.audio-indicator {
  width: 40%;
  height: 100%;
  border-radius: 20px;
  background-color: #a525a7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#00c3ff), to(#a525a7));
  background-image: linear-gradient(180deg, #00c3ff, #a525a7);
  box-shadow: 1px 0 3px 0 #27052e, 0 0 5px 2px rgba(165, 37, 167, 0.75);
}

.audio-indicator-bg {
  width: 120px;
  height: 5px;
  border-radius: 20px;
  background-color: #fff;
}

.dropdown2 {
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 0px 0px 20px 20px;
  background-color: #1c0321;
  -webkit-transition: border-radius 500ms cubic-bezier(.165, .84, .44, 1);
  transition: border-radius 500ms cubic-bezier(.165, .84, .44, 1);
}

.dropdown2:focus {
  border-bottom-left-radius: 0px;
}

.dropdown2.big {
  width: 100%;
  border-bottom-right-radius: 0px;
}

.dropdown2.small {
  max-width: 50%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 10px;
}

.dropdown2.white {
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
}

.dropdown2.white:hover {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.button-spacer {
  margin-top: 60px;
}

.vanity-content-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 3fr;
  grid-template-columns: 1fr 3fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.heading-3 {
  margin-top: 0px;
}

.dark-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 90vh;
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#010027), to(#27052e));
  background-image: linear-gradient(180deg, #010027, #27052e);
}

.dark-section.moreo-space {
  min-height: 100vh;
  padding-top: 25vh;
  padding-bottom: 25vh;
}

.dark-section.full-screen {
  height: 100vh;
  min-height: 0vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.dark-section.hide {
  display: none;
}

.dark-section.linkout {
  min-height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#27052e), to(#010027));
  background-image: linear-gradient(180deg, #27052e, #010027);
}

.dark-section.invert {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#27052e), to(#010027));
  background-image: linear-gradient(180deg, #27052e, #010027);
}

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1080px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.container.leftt-align {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.big-text-section {
  position: relative;
  z-index: 11;
  display: -ms-grid;
  display: grid;
  min-height: 100vh;
  padding-top: 25vh;
  padding-bottom: 25vh;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #fff;
  color: #010027;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.big-text-section.hide {
  display: none;
}

.stroked-h2 {
  margin-top: 25vh;
  margin-bottom: 25vh;
  color: #00c3ff;
  font-size: 3em;
  line-height: 1.4em;
  font-weight: 700;
}

.stroked-h2.link {
  border-radius: 5px;
  -webkit-transition: background-color 750ms cubic-bezier(.165, .84, .44, 1), color 750ms cubic-bezier(.23, 1, .32, 1);
  transition: background-color 750ms cubic-bezier(.165, .84, .44, 1), color 750ms cubic-bezier(.23, 1, .32, 1);
  font-size: 1em;
}

.stroked-h2.link:hover {
  padding-right: 5px;
  padding-left: 5px;
  background-color: #00c3ff;
}

.text-span {
  color: #95fff9;
}

.more-space-div {
  margin-top: 60px;
  margin-bottom: 60px;
}

.more-space-div.bottom-only {
  margin-top: 0px;
}

.more-space-div.top-only {
  margin-bottom: 0px;
}

.text-span-2 {
  color: #95fff9;
}

.white-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 80vh;
  padding-top: 120px;
  padding-bottom: 120px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  color: #010027;
  text-align: center;
}

.white-section.hide {
  display: none;
}

.icon-text-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.icon-row-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 15vh;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.controls-text-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.controls-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 120px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.control-row-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.launch-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #95fff9;
  text-decoration: none;
  text-shadow: -1px -1px 6px #00c3ff, 1px 1px 6px #a525a7;
}

.animate-block.moost {
  position: absolute;
}

.animate-block.hide {
  display: none;
}

.div-block-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-13 {
  display: -ms-grid;
  display: grid;
  height: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto auto auto auto;
}

.div-block-14 {
  min-height: 300px;
  padding-right: 0px;
  background-image: url('../images/nona-large.svg');
  background-position: 50% 50%;
  background-size: 300px 300px;
  background-repeat: no-repeat;
}

.circle-button {
  position: absolute;
  z-index: 0;
  width: 90px;
  height: 90px;
  border-style: solid;
  border-width: 2px;
  border-color: #95fff9;
  border-radius: 45px;
  background-color: rgba(165, 37, 167, 0);
  box-shadow: -2px -2px 6px 3px #00c3ff, 2px 2px 6px 3px #a525a7;
  opacity: 0.5;
}

.circle-button-text {
  position: relative;
  z-index: 1;
  margin-left: 30px;
  font-size: 3em;
  line-height: 1em;
  font-weight: 700;
  text-align: center;
}

.footer-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 150vh;
  padding-top: 60px;
  padding-bottom: 120px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#010027), to(#27052e));
  background-image: linear-gradient(180deg, #010027, #27052e);
}

.footer-section.moreo-space {
  min-height: 100vh;
  padding-top: 120px;
  padding-bottom: 120px;
}

.footer-section.full-screen {
  height: 100vh;
  min-height: 0vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.sticky-patreon {
  position: fixed;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 12;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  border-top-left-radius: 20px;
}

.sticky-patreon.right {
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
}

.container-2 {
  position: relative;
  z-index: 1;
}

.room-link-section {
  display: -ms-grid;
  display: grid;
  height: 90vh;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.room-link-section.full-screen {
  height: 100vh;
}

.host-request-form {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#010027), to(#27052e));
  background-image: linear-gradient(180deg, #010027, #27052e);
}

.contact-form-grid {
  display: -ms-grid;
  display: grid;
  margin-top: 30px;
  margin-bottom: 30px;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-15 {
  margin-bottom: 30px;
}

.request-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#010027), to(#27052e));
  background-image: linear-gradient(180deg, #010027, #27052e);
}

.container-3 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.accept-terms-section {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(1, 0, 39, 0.9);
}

.navbar {
}

.full-width-container {
  max-width: 100vw;
}

@media screen and (max-width: 991px) {
  .section {
    padding-top: 28vw;
  }
  .logo-img {
    width: 40vw;
  }
  .welcome-img {
    width: 33vw;
  }
  .welcome-div {
    margin-top: -10vw;
  }
  .here-container {
    margin-left: -1vw;
  }
  .moose {
    width: 23vw;
  }
  .moose-container {
    z-index: -1;
    padding-top: 0vw;
  }
  .logo-img-2 {
    width: 40vw;
  }
  .h1.horiz-title {
    margin-left: -212px;
  }
  .content-div {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .club-title {
    height: 56vw;
  }
  .horiz-title {
    -webkit-transform: translate(15vw, 0px) rotate(90deg);
    -ms-transform: translate(15vw, 0px) rotate(90deg);
    transform: translate(15vw, 0px) rotate(90deg);
  }
  .horiz-title._2 {
    margin-left: -90px;
    padding-top: 15vw;
    padding-left: 38px;
  }
  .right-content {
    width: 480px;
  }
  .div-block-2 {
    -webkit-transform: translate(-30vw, 0px);
    -ms-transform: translate(-30vw, 0px);
    transform: translate(-30vw, 0px);
  }
  .h2.center {
    text-align: center;
  }
  .vanity-main-section {
    padding-top: 100px;
  }
  .left-title {
    margin-right: 30px;
  }
  .vanity-content-div {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .container {
    padding-right: 4vw;
    padding-left: 4vw;
  }
}

@media screen and (max-width: 767px) {
  .section {
    padding-top: 25vw;
  }
  .welcome-div {
    margin-top: -11vw;
    margin-left: -2vw;
  }
  .here-container {
    margin-left: 1vw;
  }
  .form {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .submit-button {
    width: 100%;
  }
  .submit-button.round {
    width: auto;
  }
  .form-input {
    margin-right: 0px;
  }
  .form-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
    margin-right: 60px;
    margin-left: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .moose {
    width: 21vw;
  }
  .moose-container {
    margin-bottom: -3vw;
  }
  .main-content-block {
    width: 100vw;
  }
  .h1.center {
    font-size: 3em;
  }
  .h1.horiz-title {
    height: 120px;
    font-size: 60px;
    line-height: 55px;
  }
  .h1.horiz-title-copy {
    font-size: 60px;
    line-height: 55px;
  }
  .navbar {
      padding: 0;
  }
  .nav-grid {
    padding-right: 30px;
    text-align: center;
  }
  .nav-logo-link {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .sticky-nav {
    position: relative;
  }
  .nav-grid-2 {
    text-align: center;
  }
  .content-div {
    margin-top: 120px;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .horiz-title._2 {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    margin-left: 0px;
    padding-top: 0vw;
    padding-left: 0px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .right-content {
    width: 100%;
  }
  .paragraph-2 {
    font-size: 18px;
  }
  .h2 {
    display: block;
  }
  .h2.dark {
    font-size: 2.5em;
  }
  .h3.center {
    font-size: 1.5em;
  }
  .vanity-main-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .left-title {
    display: none;
  }
  .vanity-right-content {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .video-check-div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .vanity-content-div {
    margin-top: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .stroked-h2 {
    font-size: 2.5em;
  }
  .container-2 {
    padding-right: 15px;
    padding-left: 15px;
  }
  .host-request-form {
    padding: 40px 20px;
  }
  .request-form {
    padding: 40px 20px;
  }
  .full-width-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .menu-button {
    -webkit-transition: background-color 750ms cubic-bezier(.165, .84, .44, 1);
    transition: background-color 750ms cubic-bezier(.165, .84, .44, 1);
  }
  .menu-button:hover {
    background-color: rgba(1, 0, 39, 0.1);
  }
  .menu-button.w--open {
    background-color: rgba(1, 0, 39, 0.4);
  }
  .nav-menu {
    background-color: rgba(1, 0, 39, 0.3);
  }
}

@media screen and (max-width: 479px) {
  .logo-img {
    width: 40vw;
  }
  .form-container {
    margin-right: 20px;
    margin-left: 20px;
  }
  .body {
    background-color: #000;
  }
  .logo-img-2 {
    width: 40vw;
  }
  .h1 {
    font-size: 4em;
    line-height: 1em;
  }
  .h1.center {
    font-size: 8vw;
    line-height: 13vw;
  }
  .h1.center.big {
    font-size: 6em;
  }
  .h1.horiz-title {
    font-size: 12vw;
    line-height: 12vw;
  }
  .h1.horiz-title-copy {
    font-size: 12vw;
    line-height: 12vw;
  }
  .navbar {
  }
  .nav-grid {
    grid-auto-flow: row;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 100px;
    grid-template-rows: 100px;
  }
  .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #c4c4c4;
  }
  .sticky-nav {
    padding: 0px;
  }
  .nav-grid-2 {
    grid-auto-flow: row;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 100px;
    grid-template-rows: 100px;
  }
  .nav-link-2 {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #c4c4c4;
  }
  .content-div {
    width: 100vw;
    margin-bottom: 100px;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .horiz-title {
    width: auto;
    height: auto;
    margin-left: 0px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .right-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .h2 {
    font-size: 2.5em;
  }
  .h2.dark {
    font-size: 1.8em;
  }
  .div-block-8 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .logo-link {
    border-top: 1px solid #c4c4c4;
  }
  .vanity-right-content {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .bold-text-2 {
    font-size: 1em;
  }
  .vanity-content-div {
    margin-bottom: 100px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .heading-3 {
    font-size: 2em;
  }
  .big-text-section {
    overflow: hidden;
    width: 100vw;
  }
  .stroked-h2 {
    font-size: 1.8em;
  }
  .icon-text-div {
    width: 100%;
    margin-bottom: 60px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .icon-row-div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .controls-text-div {
    margin-left: 0px;
  }
  .controls-text-div.right {
    margin-bottom: 30px;
    margin-left: 0px;
  }
  .controls-div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .circle-button-text {
    font-size: 2em;
  }
  .container-2 {
    max-width: 100vw;
  }
  .request-form {
    height: auto;
    min-height: 100vh;
    padding-top: 186px;
  }
}

#w-node-36a8fc328d50-67cd009e {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: start;
  justify-self: start;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-ee9649ac848c-67cd009e {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: end;
  align-self: end;
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-5bf05b1584cd-5b1584ca {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-4dbf394e2bbe-cccd00b5 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-228ee07ea468-cccd00b5 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-26ab431dae65-d1cd00be {
  -ms-grid-row: span 2;
  grid-row-start: span 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-135ed0eaec60-d1cd00be {
  -ms-grid-row: 4;
  grid-row-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
}

#w-node-193128ee0a06-38cd00bf {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-193128ee0a0b-38cd00bf {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-16270ebe971e-38cd00bf {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-51e8d8ea57e8-38cd00bf {
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-cdddc7dd36f4-38cd00bf {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-bc236c704d1f-38cd00bf {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
}

#w-node-1f78e9b7037e-38cd00bf {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-df18ee62414c-38cd00bf {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 8;
  -ms-grid-row: 7;
  grid-row-start: 7;
}

#w-node-4f9a548a724d-38cd00bf {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-span: 2;
  grid-column-end: 5;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 7;
  -ms-grid-row: 6;
  grid-row-start: 6;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-1a310c368ea1-38cd00bf {
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 9;
  -ms-grid-row: 8;
  grid-row-start: 8;
}

#w-node-fc1695b3b7eb-38cd00bf {
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 6;
  -ms-grid-row: 5;
  grid-row-start: 5;
}

#w-node-e6b95b00fde9-38cd00bf {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-359477c8353f-38cd00bf {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b570a66d566e-e1cd00c3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-baa4f626032e-afcd00d6 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-b570a66d566e-afcd00d6 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-ec382133f5a5-afcd00d6 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-81de177e7823-afcd00d6 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-cf818e48bb7b-afcd00d6 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-57e2ddd36e9a-afcd00d6 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

@media screen and (max-width: 991px) {
  #w-node-193128ee0a06-38cd00bf {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: center;
    justify-self: center;
  }
}

@media screen and (max-width: 767px) {
  #w-node-26ab431dae65-d1cd00be {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-135ed0eaec60-d1cd00be {
    -ms-grid-row: 6;
    grid-row-start: 6;
    -ms-grid-row-span: 1;
    grid-row-end: 7;
  }
}

@media screen and (max-width: 479px) {
  #w-node-5bf05b1584cd-5b1584ca {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-b570a66d565e-e1cd00c3 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-b570a66d5662-e1cd00c3 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-b570a66d5666-e1cd00c3 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-b570a66d566a-e1cd00c3 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-b570a66d565e-afcd00d6 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-b570a66d5662-afcd00d6 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-b570a66d5666-afcd00d6 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-b570a66d566a-afcd00d6 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-baa4f626032e-afcd00d6 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #w-node-b570a66d566e-afcd00d6 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #w-node-ec382133f5a5-afcd00d6 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #w-node-81de177e7823-afcd00d6 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #w-node-cf818e48bb7b-afcd00d6 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #w-node-57e2ddd36e9a-afcd00d6 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
}

